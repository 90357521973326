import React from 'react';
import { Flex } from 'src/components/Flex';
import { Grid } from 'src/components/Grid';
import { FeatureItem } from 'src/components/Landing/LandingFeatureGrid';
import { Section } from 'src/components/Section';
import { SectionHeader } from 'src/components/SectionHeader';
import { Video } from 'src/components/Video';

export const DecisionDataSection = props => {
    return (
        <Section wrap vertical="lg" {...props}>
            <SectionHeader
                title="Make decisions based on data"
                description="Audentio Metrics was built to help communities understand the state of the community, the impact of initiatives, and to make informed decisions. We're here to help you get the data, provide insight, and turn those insights into action.                    "
            />

            <Flex
                align="center"
                direction={{
                    _: 'column',
                    md: 'row',
                }}
                mt={{
                    _: 'spacing-lg',
                    md: 'spacing-xl',
                }}
            >
                <Video
                    video="/videos/methods/metrics/data.mp4"
                    videoId="methods-metrics-data"
                    videoPoster={null}
                    autoPlay
                    mb={{
                        _: 'spacing-xl',
                        md: 0,
                    }}
                    pr={{ _: 0, md: 'spacing-lg', lg: 'spacing-xl' }}
                    flex={1}
                />
                <Grid
                    columns={[1, 3, 1]}
                    spacing={{
                        _: 'spacing-lg',
                        md: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        md: 350,
                    }}
                >
                    <FeatureItem
                        heading="login once, see everything"
                        content="One report using Google Data Studio to see data across platforms."
                    />
                    <FeatureItem
                        heading="prove real business ROI"
                        content="With real data results, you can better gauge what needs to be improved and what is successful."
                    />
                    <FeatureItem
                        heading="change your view"
                        content="See the big picture or dig deeper into the details by changing several components to better understand your community."
                    />
                </Grid>
            </Flex>
        </Section>
    );
};
