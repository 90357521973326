import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { Flex } from 'src/components/Flex';
import { Grid } from 'src/components/Grid';
import { Heading } from 'src/components/Heading';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import {
    ClientHighlight,
    StatBox
} from 'src/components/Methods/metrics/ClientHighlight';
import { DecisionDataSection } from 'src/components/Methods/metrics/DecisionDataSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { ThemeProvider } from 'src/components/ThemeProvider';

const subnav = [
    {
        id: 'overview',
        label: 'Overview',
    },
    {
        id: 'engagement',
        label: 'Engagement',
    },
    {
        id: 'gamification',
        label: 'Gamification',
    },
    {
        id: 'monetization',
        label: 'Monetization',
    },
    {
        id: 'compare',
        label: 'Compare',
    },
    {
        id: 'features',
        label: 'Features',
    },
];

export default function metrics({ data }) {
    return (
        <Layout
            pageTitleFull="Online community metrics by Audentio"
            siteDescription="Get insights into the activity on your online community with metrics and KPIs. Click to learn more!"
            contactForm
            methodSubNavIcon="/methods/metrics.svg"
            methodSubNavColor="#D559F0"
            subnavTitle="Metrics"
            subnav={subnav}
            wrapChildren
        >
            <MethodsLandingHero
                title="Critical key performance indicators for your community"
                video="/videos/methods/metrics/hero.mp4"
                videoPoster="/videos/methods/metrics/hero-poster.png"
                videoId="methods-metrics-hero"
                subtitle="Get the insights you need with a detailed picture of your community activity."
            />

            <DecisionDataSection id={subnav[0].id} />

            <Section
                id={subnav[1].id}
                wrap
                vertical="lg"
                bg="secondaryBackground"
                pb={[0, 0, 0]}
            >
                <Flex
                    justify={{
                        _: 'flex-start',
                        lg: 'center',
                    }}
                    pb="spacing-xl"
                >
                    <Heading kind="h2">Insights you can put to work</Heading>
                </Flex>
            </Section>

            <MediaSection
                image={data.dailyactivity.childImageSharp.fluid}
                title="Understand your members' day to day activity"
                pt={[0, 0, 0]}
                alt="Dashboard of Audentio Metrics that shows graphs, trends, comparisons, and data related to the community."
            >
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: 'Number of active members each day',
                        },
                        {
                            title:
                                'Average time active members are online each day',
                        },
                        {
                            title:
                                'Average number of activities completed by active member each day',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        />
                    ))}
                </Box>
            </MediaSection>

            <ClientHighlight
                title="Case Study"
                //logo={data.cpanel.childImageSharp.fixed}
                image={data.snipers.childImageSharp.fluid}
                description="For one of our clients, we implemented a new membership model and donation campaign as a part of the project."
                pt={[0, 0, 0]}
                alt="Graph showing a trend up in average daily activities by active users and daily time online."
            >
                <Grid
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        lg: 250,
                    }}
                >
                    <StatBox
                        title="20%"
                        subtitle="Increase in"
                        kind="Purchases"
                    />
                    <StatBox
                        title="109%"
                        subtitle="Increase in"
                        kind="Purchasable earnings"
                    />
                </Grid>
            </ClientHighlight>

            <MediaSection
                id={subnav[2].id}
                image={data.achievements.childImageSharp.fluid}
                flipped
                title="Recognize members and see the value"
                bg="primaryBackground"
                alt="Pie chart showing the number of achievements earned by the type of achievements with the trend up in experience points earned."
            >
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: 'Number of achievements earned',
                        },
                        {
                            title: 'Number of experience points earned',
                        },
                        {
                            title:
                                'Average experience points earned daily by active users',
                        },
                        {
                            title:
                                'Average achievements earned daily by active users',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        />
                    ))}
                </Box>
            </MediaSection>

            <MediaSection
                id={subnav[3].id}
                image={data.membership.childImageSharp.fluid}
                title="See your revenue stream"
                bg="secondaryBackground"
                alt="Graph showing memberships by active user upgrades that are trending up with new user upgrades also trending up."
            >
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title:
                                'Track the number of user upgrades and donations',
                        },
                        {
                            title:
                                'See changes in the number of subscriptions in each user upgrade',
                        },
                        {
                            title:
                                'Track revenue from user upgrades and donations',
                        },
                        {
                            title:
                                'See how many users have an active subscription',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        />
                    ))}
                </Box>
            </MediaSection>

            <ClientHighlight
                title="Synner Community"
                logo={data.synnerLogo.childImageSharp.fixed}
                image={data.synner.childImageSharp.fluid}
                description="Introducing a donation campaign, membership model, leaderboard, and custom achievements were all a part of our strategy to help this school and community for guitarists."
                pt={[0, 0, 0]}
                alt="Graph showing the quantity of posts created and the trend up once gamification was introduced for Synner community."
            >
                <Grid
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        lg: 250,
                    }}
                >
                    <StatBox
                        title="115%"
                        subtitle="Increase in"
                        kind="Number of posts made"
                    />
                    <StatBox
                        title="30%"
                        subtitle="Increase in"
                        kind="Number of threads made"
                    />
                </Grid>
            </ClientHighlight>

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id={subnav[4].id}
                    video="/videos/methods/metrics/compare.mp4"
                    videoId="methods-metrics-compare"
                    title="Better compare and segment for optimization and discovery"
                    bg="primaryBackground"
                    alt="Graphs and analytics automatically update when different date ranges, filters, and thread types are applied."
                >
                    <Paragraph>
                        Filter to view metrics by user group, choose a date
                        range, and compare to the previous period.
                    </Paragraph>
                    <Box pt="spacing" as="ul">
                        {[
                            {
                                title:
                                    'Figure out who your power members are and how best to serve them',
                            },
                            {
                                title:
                                    'Learn more about your user groups to better personalize your community',
                            },
                            {
                                title:
                                    'Experiment with different criteria to discover new trends',
                            },
                        ].map(highlight => (
                            <CheckListItem
                                key={highlight.title}
                                title={highlight.title}
                                as="li"
                            />
                        ))}
                    </Box>
                </MediaSection>
            </ThemeProvider>

            <LandingFeatureGrid
                id={subnav[5].id}
                bg="secondaryBackground"
                title="Get community guidance through insights"
                items={[
                    {
                        heading: 'bring data together',
                        content:
                            'Metrics from various platforms displayed together in one report using Google Data Studio.',
                    },
                    {
                        heading: 'key metrics selected',
                        content:
                            'An overview with key metrics chosen to help show the overall performance with additional metrics broken out for more detail.',
                    },
                    {
                        heading: 'from community specialists',
                        content:
                            'Our knowledge of communities and technology, acquired from over 10 years in community development.',
                    },
                    {
                        heading: 'advanced dimensions and metrics',
                        content:
                            'Additional metrics are tracked for your platform along with dimensions to break down the data.',
                    },
                    {
                        heading: 'detailed picture of user activity',
                        content:
                            'Go beyond traditional analytics and understand how your users interact with your community and each other.',
                    },
                    {
                        heading: "easily track what's important",
                        content:
                            'Reports are broken out focusing on what is important for communities, letting you get the insights you need quickly and easily.',
                    },
                ]}
                mb="0"
            />
        </Layout>
    );
}

export const query = graphql`
    query metricsQuery {
        dailyactivity: file(
            relativePath: { eq: "images/metrics/graph-daily-activity.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        membership: file(
            relativePath: { eq: "images/metrics/graph-membership.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        achievements: file(
            relativePath: { eq: "images/metrics/graph-achievements.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        snipers: file(
            relativePath: { eq: "images/metrics/snipers-highlight.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        synner: file(
            relativePath: { eq: "images/metrics/synner-highlight.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        synnerLogo: file(relativePath: { eq: "images/clients/synner-logo.png" }) {
            publicURL
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`;
